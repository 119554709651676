<template>
  <div class="main_content">
    <searchEngines
      :platform="1"
      @change="search"
      @nameChange="nameChange"
    ></searchEngines>
    <section style="margin-top: 30px">
      <el-card>
        <div slot="header" class="clearfix">
          <span>Facebook搜索结果（{{ total }}）</span>
          <el-radio-group v-model="postdata.column" @change="sortChange">
            <el-radio-button label="followers">总粉丝数</el-radio-button>
            <el-radio-button label="publish_count">总帖子数</el-radio-button>
            <el-radio-button label="overall_star_rating"
              >主页评分</el-radio-button
            >
          </el-radio-group>
        </div>
        <div v-loading="loading">
          <el-empty
            v-if="!loading && total == 0"
            description="暂无数据"
          ></el-empty>
          <listComponent :platform="1" :list="tableList"></listComponent>
          <pagination-vue
            @sizeChange="sizeInit"
            @currentChange="getList"
            :pageSize="postdata.page_num"
            :total="total"
            :firstPage="firstPage"
          />
        </div>
      </el-card>
    </section>
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
import searchEngines from "@/views/searchManage/childComponents/searchEngines.vue";
import listComponent from "@/views/searchManage/childComponents/listComponent.vue";
export default {
  components: {
    searchEngines,
    listComponent,
    paginationVue,
  },
  data() {
    return {
      tableList: [],
      sortBy: "",
      postdata: {
        name: "",
        country: [],
        followers: [],
        category: [],
        page: 1,
        page_num: 10,
        column: "followers",
      },
      loading: false,
      total: 0,
      firstPage: 1,
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    sortChange() {
      this.getList(1);
    },
    nameChange(v) {
      this.postdata.name = v;
      this.getList(1);
    },
    search(obj) {
      this.postdata.country = obj.country;
      this.postdata.category = obj.category;
      this.postdata.followers = obj.followers;
      this.postdata.name = obj.name;
      this.postdata.contact = obj.contact;
      this.postdata.website = obj.website;
      this.postdata.account = obj.account;
      this.getList(1);
    },
    sizeInit(size) {
      console.log(size);
      this.postdata.page_num = size || 10;
      this.getList(1);
    },
    getList(page) {
      this.loading = true;
      this.postdata.page = page || this.postdata.page;
      this.axios("get", `${this.$baseUrl}v1/facebook/lists`, this.postdata)
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.tableList = res.data.data;
            this.total = res.data.total;
            this.firstPage = res.data.current_page;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>